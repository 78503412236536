import { QueryPage, QueryPageResult } from './index'
import { alphaUrl, ajaxApi, ajaxJson } from '@/utils/request'
import { TCheckResultMap, TOrderStatusMap } from '@/utils/filters'
import type { TAcadType, TDegreeType } from '@/utils/filters'
import { SurveyInfo } from './survey'
import type { EduInfo, WorkInfo, FileInfo } from '@/utils/interface'

/** 订单信息 */
export type OrderInfo = {
  /** 订单号 */
  orderId: string
  /** 背调负责人账号id */
  acctId: string
  /** 背调负责人姓名 */
  contactName: string
  /** 批次号 */
  batchId: string
  /** 候选人id */
  candidateId: string
  /** 候选人姓名 */
  candidateName: string
  /** 背调结果/背调风险 */
  checkResult: TCheckResultMap
  /** 用人部门id */
  officeId: string
  /** 用人部门名称 */
  officeName: string
  /** 套餐id */
  packageId: string
  /** 套餐名称 */
  packageName: string
  /** 职位id */
  positionId: string
  /** 职位名称 */
  positionName: string
  /** 订单金额 */
  price: number
  /** 下单时间 */
  cTime: string
  /** 授权时间 */
  startTime: string
  /** 完成时间 */
  endTime: string
  /** 订单状态 */
  status: string
  /** 实际反馈时间 */
  realFeedbackTime: string
  /** 报告文件ID */
  reportId: string
}

/** 订单中调查项信息 */
export type OrderSurveyInfo = SurveyInfo & {
  /** 数量 */
  num: number
}
/** 批量订单信息 */
export type BatchOrderInfo = {
  /** 背调负责人账号id */
  acctId: string
  /** 批处理id */
  batchId: string
  /** 候选人列表 */
  candidateList: string[] // candidateId的数组
  /** 抄送邮箱 */
  ccEmails: string
  /** 职业资格证书列表 */
  credentialList: string[]
  /** 专业资格证书列表 */
  professionList: string[]
  /** 需核验的学校列表 */
  schoolList: string[]
  /** 教育经历 */
  eduList: EduInfo[]
  /** 订单备注 */
  orderRemark: string
  /** 套餐id */
  packageId: string
  /** 套餐名称 */
  packageName: string
  /** 价格 */
  price: number
  /** 反馈时间 */
  preFeedbackTime: number
  /** 调查项 */
  surveyList: OrderSurveyInfo[]
  /** 工作经历 */
  workList: WorkInfo[]
}
/** 本地使用的订单信息 */
export type MBatchOrderInfo = {
  /** 选中的公司 */
  companyIds: string[]
  /** 选择的学历 */
  xlList: TAcadType[]
  /** 选择的学位 */
  xwList: TDegreeType[]
} & BatchOrderInfo

type OrderInfoPage = QueryPage & Partial<OrderInfo>

/** 订单分页查询 */
export const getOrderPage = (params: OrderInfoPage) =>
  ajaxApi('get', alphaUrl + '/order/query/page', params) as Promise<QueryPageResult<OrderInfo>>

/** 批量新增订单 */
export const saveOrderBatch = (params: BatchOrderInfo) =>
  ajaxJson('post', alphaUrl + '/order/batch/add', params) as Promise<unknown>

/** 获取报告访问url */
export const getOrderReport = (reportId: string) =>
  ajaxApi('get', alphaUrl + '/order/report/access', { reportId }) as Promise<FileInfo>

/** 重新发送授权短信 */
export const resendAuthSms = (params: Object) =>
  ajaxApi('post', alphaUrl + '/order/sms/resend', params) as Promise<Array<string>>
